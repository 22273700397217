import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { _auth, _getUser } from '../../../../../../../services/login.service';
import { primary, error } from '../../../../../../../configs/CustomColors';
import { MenuItem, Select } from '@material-ui/core';
import { formataCNPJ, formataMoeda } from '../../../../../../../util/Auxiliary';
import { _geraPagamento } from 'services/empresa.service';


export default function ModalPagamentoParceiro({ values, close, isLoggedUser, setBackdrop }) {
    const [cnpj, setCnpj] = useState(formataCNPJ(values.cnpj));
    const [valor, setValor] = useState(formataMoeda(values.vr_valor ? values.vr_valor : 0.00) || 0.00);
    const [tipo, setTipo] = useState(isLoggedUser ? "MP" : "DN");
    const [erroValor, setErroValor] = useState(false);

    const generatePayment = async () => {
        if (isLoggedUser) {
            setBackdrop(true);
        }
        let letcnpj = cnpj.replace('.', '').replace('.', '').replace('/', '').replace('-', '');
        const headers = { headers: { "Authorization": "Bearer " + values.token } };
        await _geraPagamento({
            cnpj: letcnpj,
            //TODO: RETIRAR COMENTÁRIO E APAGAR VR_VALOR QUE ESTÁ DESCOMENTADO
            // vr_valor: !isLoggedUser ? valor.replace(",", ".") : "30.00", 
            vr_valor: valor.replace(",", '.'),
            tipo
        }, headers)
            .then(response => {
                if (isLoggedUser) {
                    setBackdrop(false);
                }
                if (tipo === "DN" && response.data.sit === '200') {
                    close("S");
                    // changeRoute()
                } else if (tipo === "MP" && response.data.sit === '200') {
                    close(response.data.link_mp);
                }
                //console.log(response.data);
            })
            .catch(erro => {
                if (isLoggedUser) {
                    setBackdrop(false);
                }
            });
    }

    useEffect(() => {
        if (valor === 0 || valor === 0.00) {
            setErroValor("O valor não pode ser R$0,00");
        } else {
            setErroValor(false);
        }
    }, [valor]);


    return (
        <div>
            <div className="app-login-header mb-4">
                <h1>Informar Pagamento de Mensalidade da Empresa</h1>
            </div>
            <form>
                <fieldset>
                    {/* {!isLoggedUser ? <> */}
                    <TextField
                        label={"CNPJ"}
                        fullWidth
                        onChange={(event) => setCnpj(formataCNPJ(event.target.value))}
                        value={cnpj}
                        margin="normal"
                        className="mt-1 my-sm-3"
                    />
                    <TextField
                        label={"Valor"}
                        fullWidth
                        error={erroValor}
                        // disabled
                        helperText={erroValor}
                        onChange={(event) => {
                            let value = formataMoeda(event.target.value || 0.00);
                            setValor(value);
                        }}
                        // value={"R$ 30,00"}
                        value={valor}
                        margin="normal"
                        className="mt-1 my-sm-3"
                    />
                    <Select label="Tipo de Pagamento"
                        fullWidth
                        value={tipo}
                        disabled={isLoggedUser}
                        margin="normal"
                        className="mt-1 my-sm-3"
                        onChange={event => { setTipo(event.target.value) }}>
                        <MenuItem value={"MP"}>Mercado Pago</MenuItem>
                        <MenuItem value={"DN"}>Dinheiro</MenuItem>
                    </Select>
                    {/* </> :
                        <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between", alignItems: "center" }}>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <em style={{ fontSize: 12, color: "gray" }}>Empresa: </em>
                                <p style={{ fontSize: 15, marginTop: 3 }}>{sessionStorage.getItem("@Emissor/nomeEmpresa")}</p>
                            </div>
                            <div>
                                <em style={{ fontSize: 12, color: "gray" }}>CNPJ: </em>
                                <p style={{ fontSize: 15, marginTop: 3 }}>{cnpj}</p>
                            </div>
                            <div>
                                <em style={{ fontSize: 12, color: "gray" }}>Modalidade: </em>
                                <p style={{ fontSize: 15, marginTop: 3 }}>Mensalidade</p>
                            </div>

                            <div>
                                <em style={{ fontSize: 12, color: "gray" }}>Valor: </em>
                                <p style={{ fontSize: 18, marginTop: 3, color: "black" }}>R$ 30,00</p>
                            </div>
                        </div> 
                    }*/}
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                        {!isLoggedUser ? <div className="mb-3 d-flex align-items-end">
                            <Button disabeld={erroValor} onClick={() => {
                                generatePayment()
                            }} variant="contained" style={{ backgroundColor: primary, color: "white", textAlign: 'flex-end' }}>
                                Pagar
                            </Button>
                        </div>
                            :
                            <div className="mb-3 d-flex align-items-end" style={{ display: "flex", width: "80%" }}>
                                <Button disabeld={erroValor} onClick={() => {
                                    generatePayment()
                                }} variant="outlined" style={{ backgroundColor: "white", color: "blue", textAlign: "center" }}>
                                    Mercado Pago
                                </Button>
                            </div>
                        }

                        {isLoggedUser && <div className="mb-3 d-flex align-items-end">
                            <Button onClick={() => {
                                close("C")
                            }} variant="contained" style={{ backgroundColor: error, color: "white", textAlign: 'flex-end' }}>
                                Cancelar
                            </Button>
                        </div>}
                    </div>
                </fieldset>
            </form>

        </div>
    )
}