import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Stepper from '../app/routes/components/routes/stepper/linear/HorizontalLabelPositionBelowStepper';
import {
  hideMessage,
} from 'actions/Auth';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));


const SignUp = (props) => {
  const [backdrop, setBackdrop] = useState(false);
  const classes = useStyles();
  const history = useHistory();


  const dispatch = useDispatch();
  const { showMessage, authUser } = useSelector(({ auth }) => auth);

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 3000);
    }
    if (authUser !== null) {
      history.push('/');
    }
  }, [showMessage, authUser, history, dispatch]);

  return (
    <>

      <Backdrop style={{ zIndex: '99999' }} className={classes.backdrop} open={backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <div
        className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content" style={{ padding: 15 }}>
          <Stepper setBackdrop={setBackdrop} history={history}/>
        </div>
      </div>
    </>
  )
};


export default SignUp;
