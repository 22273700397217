import * as yup from 'yup';


const schema = yup.object().shape({
    name: yup.string().required("Campo obrigatório"),
    email: yup.string().required("Campo obrigatório"),
    password: yup.string().required("Campo obrigatório"),
    cpf_cnpj: yup.string().required("Campo obrigatório")
});


export default schema