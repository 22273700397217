import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { Fab } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import IntlMessages from 'util/IntlMessages';
import { ToastContainer, toast } from 'react-toastify';
import {
  hideMessage,
  notAllowed,
  userSignInSuccess
} from 'actions/Auth';
import { _auth, _getEmp, _getUser } from '../services/login.service';
import FormDialog from 'app/routes/components/routes/dialogs/formAlerts/FormDialog';
import CheckBoxListControl from 'app/routes/components/routes/list/checkBoxListControl/CheckBoxListControl';
import { makeStyles } from '@material-ui/core/styles';
import { logoImg, primary } from 'configs/CustomColors';
import WhatsappIcon from '@material-ui/icons/WhatsApp';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const success = (message) => {
  toast.success(message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 3000
  });
}

const error = (message) => {
  toast.error(message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 3000
  });
}

function openWhatsapp() {
  window.open("https://api.whatsapp.com/send?phone=5562982609109", '_blank').focus();
}

const SelectEmpresa = ({ empresas, thispatch }) => {
  return (
    <CheckBoxListControl data={empresas} locale={"empresas"} dispatch={thispatch} />
  )
}

const SignIn = (props) => {
  const classes = useStyles();
  const [empresas, setEmpresas] = useState([]);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [form, setForm] = useState(false);
  const [token, setToken] = useState();
  const [user, setUser] = useState();
  const dispatch = useDispatch();
  const [backdrop, setBackdrop] = useState(false);
  const { showMessage, authUser } = useSelector(({ auth }) => auth);

  useEffect(() => {
    if (props.history.location.state) {
      success(props.history.location.state.msg);
      props.history.push({ pathName: '/', state: undefined });
    }
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (authUser !== null && sessionStorage.getItem("@Emissor/cnpj")) {
      props.history.push('/');
    }

  }, [showMessage, authUser, props.history, dispatch]);

  const closeForm = () => setForm(false);

  const thispatch = async (empresa) => {
    console.warn(user, empresa);
    if (empresa.parceiro) {
      sessionStorage.setItem("@Emissor/cnpj", 1);

      dispatch(notAllowed(true));
      dispatch(userSignInSuccess({
        email,
        password,
        token: token,
        name: sessionStorage.getItem("@Emissor/UserName"),
        mail: sessionStorage.getItem("@Emissor/UserMail")
      }));
      setBackdrop(false);
    } else {
      const today = moment().format("YYYY-MM-DD");
      let dataAcesso = empresa.acesso?.dt_fim_acesso ?
        empresa.acesso?.dt_fim_acesso :
        moment().add(-1, "day").format("YYYY-MM-DD")
      setBackdrop(true);

      sessionStorage.setItem("@Emissor/idEmpresa", empresa.dadosLocais.id);
      sessionStorage.setItem("@Emissor/cnpj", empresa.dadosLocais.nu_cnpj);
      sessionStorage.setItem("@Emissor/nomeEmpresa", empresa.dadosLocais.tx_razao_social);
      sessionStorage.setItem("@Emissor/crt", empresa.crt);
      sessionStorage.setItem("@Emissor/logo", empresa.logo_nfce);
      sessionStorage.setItem("@Emissor/dataCertificado", empresa.dt_venc_certificado);
      //sessionStorage.setItem("@Emissor/dataVencimento", empresa.ambiente === "2" ? "2040-01-01" : dataAcesso); //FIXME: Mudei aqui para liberar
      sessionStorage.setItem("@Emissor/dataVencimento", '2024-12-25'); ///FIXME:Mudei aqui para liberar
      //sessionStorage.setItem("@Emissor/ambiente", empresa.ambiente); //FIXME:Mudei aqui para liberar

      dispatch(notAllowed(dataAcesso !== today));

      //if (empresa.ambiente === "2") { dataAcesso = "2040-01-02"; dispatch(notAllowed(false)); } //FIXME: Mudei aqui para liberar
      dispatch(userSignInSuccess({ email, password, token: token, name: user.name, mail: user.mail }));
      setBackdrop(false);
    }
  }

  const makeAuth = async () => {
    setBackdrop(true);
    await _auth({ email, password }).then(async response => {
      console.log(response.data.msg);
      sessionStorage.setItem("@Emissor/Token", response.data.msg);

      let tokenf = response.data.msg;
      setToken(response.data.msg);
      await _getUser({ headers: { "Authorization": "Bearer " + response.data.msg } }).then(async response => {

        sessionStorage.setItem("@Emissor/UserName", response.data.name);
        sessionStorage.setItem("@Emissor/uid", response.data.id);
        sessionStorage.setItem("@Emissor/UserMail", response.data.email);
        sessionStorage.setItem("@Emissor/UserImg", response.data.imagem);
        sessionStorage.setItem("@Emissor/Type", response.data.tipo);

        setUser(response.data);
        if (response.data.tipo !== "PC") {
          await _getEmp('', { headers: { "Authorization": "Bearer " + tokenf } }).then(async response => {
            if (response.data.length === 0) {
              setBackdrop(false);
              error("Nenhuma empresa encontrada para este usuário.");
              sessionStorage.removeItem("@Emissor/Token");
            } else {
              setEmpresas(response.data);
              setForm(true);
              setBackdrop(false);
            }
          })
            .catch(erro => { setBackdrop(false); error("Ocorreu um erro ao realizar login. Confira os dados informados") })
        } else {
          setBackdrop(false);
          thispatch({ parceiro: true });
        }
      }).catch(erro => { setBackdrop(false); error("Ocorreu um erro ao realizar login. Confira os dados informados") });
    })
      .catch((erro) => {
        setBackdrop(false); error("Ocorreu um erro ao realizar login. Confira os dados informados")
      })
  }


  return (
    <>

      <Backdrop style={{ zIndex: '99999' }} className={classes.backdrop} open={backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div
        className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <ToastContainer />
        <div className="app-login-main-content">

          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <Link className="logo-lg" to="/app/vendas/area" title="Logo">
              {(() => {
                if (sessionStorage.getItem("@Emissor/logoHorizontal") !== 'undefined') {
                  return <img src={`data:image/png;base64,${sessionStorage.getItem("@Emissor/logoHorizontal")}`}
                    style={{ width: "100%", height: "100%" }} alt="Logo" title="Logo" />
                } else {
                  return <img src={require('../assets/images/icon.png')} style={{ width: "100%", height: "100%" }} alt="Logo" title="Logo" />
                }
              })()}

            </Link>
          </div>

          <div className="app-login-content">
            <div className="app-login-header mb-4">
              <h1>Bem-vindo!</h1>
            </div>

            <div className="app-login-form">
              <form>
                <fieldset>
                  <TextField
                    label={<IntlMessages id="appModule.email" />}
                    fullWidth
                    onChange={(event) => setEmail(event.target.value)}
                    defaultValue={email}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />
                  <TextField
                    type="password"
                    label={<IntlMessages id="appModule.password" />}
                    fullWidth
                    onChange={(event) => setPassword(event.target.value)}
                    defaultValue={password}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />

                  <div className="mb-3 d-flex align-items-center justify-content-between">
                    <Button onClick={() => {
                      makeAuth();
                    }} variant="contained" style={{ backgroundColor: primary, color: "white" }}>
                      <IntlMessages id="appModule.signIn" />
                    </Button>

                    <div style={{ display: "flex", flexDirection: 'column' }}>
                      <Link to="/signup">
                        <IntlMessages id="signIn.signUp" />
                      </Link>
                    </div>
                  </div>

                </fieldset>
              </form>
            </div>
          </div>

        </div>
        {form && <FormDialog maxWidth={"lg"} title="Selecione uma empresa para trabalhar"
          close={closeForm} children={<SelectEmpresa setForm={closeForm} empresas={empresas} thispatch={thispatch} />} />}
      </div>
      <Fab style={{
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
        backgroundColor: "#128c7e",
        color: 'white'
      }} variant="extended" aria-label="add"
        onClick={openWhatsapp}>
        <WhatsappIcon />
        Suporte
      </Fab>
    </>
  );
};


export default SignIn;
