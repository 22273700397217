import * as yup from 'yup';


export const empresaSchema = yup.object().shape({
    razao_social: yup.string().required("Campo obrigatório"),
    cnpj: yup.string().min(17, "Informe o CNPJ corretamente").required("Campo obrigatório"),
    nome_fantasia: yup.string().required("Campo obrigatório"),
    crt: yup.string().required("Campo obrigatório"),
    email: yup.string().email("Informe o e-mail corretamente").required("Campo obrigatório"),
    ie: yup.string().required("Campo obrigatório"),
    im: yup.string().required("Campo obrigatório"),
    telefone: yup.string().min(13, "Informe o telefone corretamente").required("Campo obrigatório"),
    cnae: yup.string().required("Campo obrigatório"),

    //ENDEREÇO
    cep: yup.string().required("Campo obrigatório"),
    bairro: yup.string().required("Campo obrigatório"),
    logradouro: yup.string().required("Campo obrigatório"),
    cmun: yup.string().required("Campo obrigatório"),
    cuf: yup.string().required("Campo obrigatório"),
    uf: yup.string().required("Campo obrigatório"),
    nro: yup.string().required("Campo obrigatório"),
    //complemento: yup.string().required("Campo obrigatório"),
    cidade: yup.string().required("Campo obrigatório"),

    //FISCAIS

    // logo_nfce: edit.logo_nfce ? `data:image/gif;base64,${edit.logo_nfce}` : '',
    // logo_nfe: edit.logo_nfe ? `data:image/gif;base64,${edit.logo_nfe}` : '',
})



// export const stepThreeSchema = ;


// export const stepFourSchema = ;




// yup.object().shape({
    // razao_social: yup.string().required("Campo obrigatório"),
    // cnpj: yup.string().required("Campo obrigatório"),
    // nome_fantasia: yup.string().required("Campo obrigatório"),
    // crt: yup.string().required("Campo obrigatório"),
    // email_empresa: yup.string().required("Campo obrigatório"),
    // ie: yup.string().required("Campo obrigatório"),
    // im: yup.string().required("Campo obrigatório"),
    // emp_telefone: yup.string().required("Campo obrigatório"),
    // cnae: yup.string().required("Campo obrigatório"),
// });