import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import IntlMessages from 'util/IntlMessages';
import { ToastContainer } from 'react-toastify';
import { _auth, _getUser } from '../../../../../../../services/login.service';
import { primary } from 'configs/CustomColors';
import { makeStyles } from '@material-ui/core/styles';
import { FormHelperText } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));


export default function LoginParceiro({ setDadosPagamento, close, createData, values }) {
    const classes = useStyles();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [backdrop, setBackdrop] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const makeAuth = async () => {
        setErrorMessage(false);
        setBackdrop(true);
        await _auth({ email, password })
            .then(async response => {
                if (response.data.sit === '23') {
                    setBackdrop(false);
                    setErrorMessage("Usuário ou senha incorretos. Tente novamente.");
                } else {
                    if (response.data.parceiro) {
                        setBackdrop(false);
                        sessionStorage.setItem("@Emissor/Token", response.data.msg);
                        createData(response.data.msg);
                        // setDadosPagamento()
                        close();
                    } else {
                        setBackdrop(false);
                        setErrorMessage("Usuário Não é Parceiro. Tente novamente com usuário credenciado.");
                    }
                }
            })
    }

    return (
        <div>
            <Backdrop style={{ zIndex: '99999' }} className={classes.backdrop} open={backdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <div>
                <ToastContainer />
                <div className="app-login-form">
                    <div className="app-login-header mb-4">
                        <h1>Informar seu Login de Parceiro</h1>
                    </div>
                    <form>
                        <fieldset>
                            <TextField
                                label={<IntlMessages id="appModule.email" />}
                                fullWidth
                                onChange={(event) => setEmail(event.target.value)}
                                defaultValue={email}
                                margin="normal"
                                className="mt-1 my-sm-3"
                            />
                            <TextField
                                type="password"
                                label={<IntlMessages id="appModule.password" />}
                                fullWidth
                                onChange={(event) => setPassword(event.target.value)}
                                defaultValue={password}
                                margin="normal"
                                className="mt-1 my-sm-3"
                            />
                            <div style={{ display: "flex", width: "100%", alignItems: "flex-start", justifyContent: "flex-start" }}>
                                <FormHelperText error={!!errorMessage}>{errorMessage}</FormHelperText>
                            </div>
                            <div className="mb-3 d-flex align-items-end">
                                <Button onClick={() => {
                                    makeAuth();
                                }} variant="contained" style={{ backgroundColor: primary, color: "white", textAlign: 'flex-end' }}>
                                    <IntlMessages id="appModule.signIn" />
                                </Button>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>

        </div>
    )
}