import api from '.';

export const _getNatureza = async (json, headers) => {
    return await api.post('naturezas_da_operacao/listar', json, headers).then(response => { return response });
}

export const _saveNatureza = async (json, headers) => {
    return await api.post('naturezas_da_operacao', json, headers).then(response => { return response });
}

export const _updateNatureza = async (json, headers) => {
    return await api.put('naturezas_da_operacao', json, headers).then(response => { return response });
}

export const _deleteNatureza = async (json, headers) => {
    return await api.delete('naturezas_da_operacao', {
        data: json,
        headers: { "Authorization": "Bearer " + sessionStorage.getItem("@Emissor/Token") }
    }).then(response => { return response });
}