import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import LaunchIcon from '@material-ui/icons/Launch';
import { Button, Typography } from '@material-ui/core';
import moment from 'moment';

const ModalCertificadoVencido = ({ open, close, dados }) => {

    //console.log("MODAL DADOS", dados);

    return (
        <Dialog fullWidth maxWidth={'sm'}
            open={open}
            onClose={close}
        >
            <DialogTitle>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row', justifyContent: 'space-between', width: "100%", alignItems: 'center',
                    backgroundColor: '#fff'
                }}>
                    <div style={{
                        display: 'flex', width: "100%", flexDirection: "row"
                    }} >
                        <Typography>{dados[0].descricao}</Typography>
                    </div>
                    <div style={{
                        display: 'flex', width: "100%", justifyContent: 'flex-end'
                    }}>
                        <IconButton
                            aria-label="close"
                            onClick={close}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent style={{ backgroundColor: '#fff', }} >
                <DialogContentText style={{ alignItems: 'center' }}>
                    {dados[0].imagem &&
                        <img style={{
                            display: 'block',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            width: '50%',
                        }} height={150} src={`data:image/png;base64,${dados[0].imagem}`}></img>
                    }
                    <Typography style={{ fontWeight: 'bold', textAlign: "center", color: 'black' }}>
                        {`Seu certificado digital vence em ${moment(sessionStorage.getItem("@Emissor/dataCertificado"))
                            .format("DD/MM/YYYY")}. Cliquei no botão abaixo para atualizá-lo.`}
                    </Typography>
                    <Typography style={{ textAlign: "center" }}>{dados[0].texto}</Typography>
                    <Button variant='outlined'
                        onClick={() => {
                            window.open(dados[0].url, '_blank', 'noopener,noreferrer');
                            close();
                        }}
                        style={{
                            marginTop: 15, color: "#4d79ff", borderColor: "#4d79ff", borderWidth: 2, marginLeft: 'auto',
                            marginRight: 'auto',
                            display: 'block',
                        }}>
                        Atualizar Certificado Digital
                        <LaunchIcon style={{ color: "#4d79ff", marginLeft: 5 }} size={30} />
                    </Button>
                </DialogContentText>
            </DialogContent>
        </Dialog >
    )



}

export default ModalCertificadoVencido;
