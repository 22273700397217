import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

function Dialog({ label, icon, setFieldValue, fieldName, accept }) {
  const [file, setFile] = useState();
  //   noClick: false,
  //   noKeyboard: true
  const onDrop = useCallback((acceptedFiles) => {
    //console.log("FILES ACEITAS", acceptedFiles);
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()
      reader.readAsDataURL(file);

      // reader.onabort = () => //console.log('file reading was aborted')
      // reader.onerror = () => //console.log('file reading has failed')
      reader.onload = () => {
        const binaryStr = reader.result;
        setFieldValue(binaryStr);
        setFile(file.path);
      }
    })

  }, []);

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    onDrop,
    accept: accept ? accept['accept'] : null
  });

  return (
    <div className="dropzone-card" >
      <div className="dropzone">
        <div {...getRootProps({ className: 'dropzone-file-btn' })} style={{ alignItems: "center", justifyContent: "center", borderColor: file ? "green" : "black" }}>
          <input {...getInputProps()} onClick={open} />
          <i className={`zmdi  zmdi-${file ? 'check-circle' : icon} zmdi-hc-5x`} style={{ color: file ? "green" : null }} />
          <p style={{ fontWeight: "bold", textAlign: "center", color: file ? "green" : null }}>{label}</p>
        </div>
        {/* <ul style={{ marginLeft: "40%" }}>{file}</ul> */}
      </div>
    </div>
  );
}

export default Dialog;