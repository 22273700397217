import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import UserInfo from 'components/UserInfo';
import { COLLAPSED_DRAWER, FIXED_DRAWER, HORIZONTAL_NAVIGATION } from 'constants/ActionTypes';
import { toggleCollapsedNav, updateWindowWidth } from 'actions/Setting';
import SideBarContent from "./SideBarContent";
import IconButton from '@material-ui/core/IconButton';
// import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';


const SideBar = () => {
  const dispatch = useDispatch();
  const { drawerType, width, navigationStyle } = useSelector(({ settings }) => settings);
  const { navCollapsed } = useSelector(({ common }) => common);

  useEffect(() => {
    window.addEventListener('resize', () => {
      //console.log("RESIZE");
      // dispatch(updateWindowWidth(window.innerWidth))
    });
  }, [dispatch]);

  const onToggleCollapsedNav = (e) => {
    //console.log("onToggleCollapsedNav", e);
    dispatch(toggleCollapsedNav(true));
  };

  // let drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'd-xl-flex' : drawerType.includes(COLLAPSED_DRAWER) ? '' : 'd-flex';
  let drawerStyle = navCollapsed ? 'd-xl-flex':'';
  let type = 'permanent';
  if (drawerType.includes(COLLAPSED_DRAWER) || (drawerType.includes(FIXED_DRAWER) && width < 1200)) {
    type = 'temporary';
  }

  if (navigationStyle === HORIZONTAL_NAVIGATION) {
    drawerStyle = '';
    type = 'temporary';
  }

 
  //console.log(drawerStyle);

  return (
    <div className={`app-sidebar d-none ${drawerStyle}`}>
      <Drawer className="app-sidebar-content"
        variant={'persistent'}
        // open={navCollapsed}
        // anchor={'left'}
        open={true}
        onClose={onToggleCollapsedNav}
        classes={{
          paper: 'side-nav',
        }}
      >
         <div>
          <IconButton onClick={onToggleCollapsedNav}> 
          <ChevronLeftIcon />
          </IconButton>
        </div>
        <UserInfo />
        <SideBarContent />
        <div style={{
          // position: 'absolute',
          // bottom: 0,
          height: '140px',
          // marginTop: '40px',
          width: "100%"
        }}>
          {sessionStorage.getItem("@Emissor/logoHorizontal") !== 'undefined' ?
            <img src={`data:image/png;base64,${sessionStorage.getItem("@Emissor/logoHorizontal")}`}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            :
            <img src={require('../../assets/images/icon.png')} style={{ width: "100%", height: "100%" }} alt="Logo" title="Logo" />
          }
        </div>
      </Drawer>
    </div>
  );
};


export default withRouter(SideBar);


// const SideBar = () => {
  
//     return (
//         <div className={`app-sidebar d-none ${drawerStyle}`}>
//             <Drawer className="app-sidebar-content"
//                     variant={type}
//                     open={type.includes('temporary') ? navCollapsed : true}
//                     onClose={onToggleCollapsedNav}
//                     classes={{
//                         paper: 'side-nav',
//                     }}
//             >
//                 <UserInfo/>
//                 <SideBarContent/>
//             </Drawer>
//         </div>
//     );
// };


// export default withRouter(SideBar);

