import React, { useEffect, useState } from 'react';
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import configureStore, { history } from './store';
import './firebase/firebase';
import App from './containers/App';
import './configs/CustomColors';
import ModalCertificadoVencido from './components/ModalCertificado';
import { _getAnounces, _getStyles } from './services/system.service';
import moment from 'moment';


export const store = configureStore();

const MainApp = () => {
  const [modal, setModal] = useState(false);
  const [anuncioDados, setAnuncioDados] = useState([]);

  const getAnounces = async (id) => {
    await _getAnounces(id).then(response => {
      setAnuncioDados(response.data);
      let passado = moment().add(10, 'days');
      if (sessionStorage.getItem("@Emissor/uid")) {
        // if(moment(sessionStorage.getItem("@Emissor/dataCertificado")).format("DD/MM/YYYY") !== 'Invalid date'){
        if ((sessionStorage.getItem("@Emissor/dataCertificado") !== "Nao informado")
          || (moment(sessionStorage.getItem("@Emissor/dataCertificado")).format("DD/MM/YYYY") !== "Invalid date")) {
          if (passado.format("DD/MM/YYYY") <= moment(sessionStorage.getItem("@Emissor/dataCertificado")).format("DD/MM/YYYY")) {
            setModal(true);
          }
        }
        // }
      }
    })
      .catch(() => undefined);
  }

  const getStyles = async () => {
    await _getStyles(window.location.origin)
      .then(async response => {
        sessionStorage.setItem("@Emissor/dangerColor", response.data.danger_color);
        sessionStorage.setItem("@Emissor/successColor", response.data.success_color);
        sessionStorage.setItem("@Emissor/primaryColor", response.data.primary_color);
        sessionStorage.setItem("@Emissor/warningColor", response.data.warning_color);
        sessionStorage.setItem("@Emissor/barColor", response.data.bar_color);
        sessionStorage.setItem("@Emissor/secondaryColor", response.data.secondary_color);
        sessionStorage.setItem("@Emissor/logoHorizontal", response.data.logo_horizontal);

        if (!sessionStorage.getItem("@Emissor/parceiro")) {
          sessionStorage.setItem("@Emissor/parceiro", response.data.razao_social);
          window.location.href = '/';
        }
        getAnounces(response.data.id);

      })
      .catch(() => undefined);
  }

  useEffect(() => {
    if (!sessionStorage.getItem("@Emissor/parceiro")) {
      getStyles();
    }
    // handleDispatchAllowed()
  }, []);

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/" render={props => <App {...props} />} />
        </Switch>
      </ConnectedRouter>
      {modal && <ModalCertificadoVencido open={modal} close={() => setModal(false)} dados={anuncioDados} />}
    </Provider>
  );
}

export default MainApp;