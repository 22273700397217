import api from '.';

export const _getFormaPG = async (json, headers) => {
    return await api.post('formas_de_pagamento/listar', json, headers).then(response => { return response });
}

export const _saveFormaPG = async (json, headers) => {
    return await api.post('formas_de_pagamento', json, headers).then(response => { return response });
}

export const _updateFormaPG = async (json, headers) => {
    return await api.put('formas_de_pagamento', json, headers).then(response => { return response });
}

export const _dropFormaPG = async (data, headers) => {
    //console.log({ headers, data });
    return await api.delete('formas_de_pagamento', {
        headers: { "Authorization": "Bearer " + sessionStorage.getItem("@Emissor/Token") },
        data
    }).then(response => { return response });
}