import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import { Badge } from "reactstrap";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import LongMenu from "../../menuNPaper/long/LongMenu";
import CardBox from '../../../../../../components/CardBox/index';
import ProdICon from '@material-ui/icons/AddBox';
import ServICon from '@material-ui/icons/Build';
import { error, success } from "configs/CustomColors";

const ListProdutosServicos = ({ data, setEdit, setForm, alert }) => {
    return (
        <List>
            {data.map((item, index) => {
                return (
                    <CardBox
                        styleName="col-lg-12 col-md-6 col-12"
                        cardStyle="p-0"
                        heading={""}
                        headerOutside
                    >
                        <ListItem key={item.tx_descricao}>
                            <ListItemAvatar>
                                <Avatar>
                                    {item.lo_servico === "S" ? <ServICon /> : <ProdICon />}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText style={{ width: "15%" }} className="br-break" primary={item.tx_descricao} secondary={item.lo_servico === 'S' ? "Serviço" : "Produto"} />
                            <ListItemText className="br-break" primary={item.tx_cod_personalizado} />
                            <ListItemText className="br-break" primary={item.vr_valor ? "R$" + item.vr_valor.replace('.', ',') : ''} />
                            <Badge className="mr-4 mt-2 text-uppercase" style={{
                                backgroundColor: item.lo_ativo === 'S' ? success : error,
                                color: "white"
                            }} pill>{item.lo_ativo === 'S' ? "Ativo" : "Inativo"}</Badge>
                            <ListItemSecondaryAction>
                                <LongMenu locale="list" setForm={setForm} setEdit={(item) => {
                                    setEdit(item);
                                }} value={item} alert={alert} />
                            </ListItemSecondaryAction>
                        </ListItem>
                    </CardBox>
                );
            })}
        </List>
    );
};

export default ListProdutosServicos;
