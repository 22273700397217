import React, { useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import { Badge } from "reactstrap";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import moment from 'moment';
import EyeIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import CardBox from '../../../../../../components/CardBox/index';
//import NoteIcon from '@material-ui/icons/Note';
import { error, success, warning, secondary } from "configs/CustomColors";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const getBadge = (item) => {
    const purple = '#6f42c1';

    if (item.lo_pedido === 'S') {
        return (
            <Badge className="mr-4 mt-2 text-uppercase" style={{ backgroundColor: secondary, color: "white" }} pill>
                Orçamento
            </Badge>
        );
    }

    // Verifica se a venda foi clonada
    if (item.tx_msg_status_venda_nf === 'Venda Clonada') {
        return (
            <Badge className="mr-4 mt-2 text-uppercase" style={{ backgroundColor: purple, color: "white" }} pill>
               Venda Clone
            </Badge>
        );
    }

    // Determina a cor e o texto com base no status da venda
    const statusColor =
        item.tx_status_venda_nf === '100' || item.tx_status_venda_nf === '150' || item.tx_status_venda_nf === 'L000'
            ? success
            : item.tx_status_venda_nf === '101'
                ? error
                : warning;

    const statusText =
        item.tx_status_venda_nf === '100' || item.tx_status_venda_nf === '150' || item.tx_status_venda_nf === 'L000'
            ? "Nota Emitida"
            : item.tx_status_venda_nf === '101'
                ? "Cancelada"
                : "Não Emitida";

    return (
        <Badge className="mr-4 mt-2 text-uppercase" style={{ backgroundColor: statusColor, color: "white" }} pill>
            {statusText}
        </Badge>
    );
};

const ListVendas = ({ data, setEdit, venda, emitir, setModalClone }) => {
    const [modal, setModal] = useState(null);

    const handleClickClone = (event) => {
        //Crone
        setModalClone(event)
    };

    const checkOption = (item, index) => {
        setEdit(item, index);
    };

    const fecharModal = function name() {
        setModal(null)
    }

    const clone = (item, index) => {
        //alert('Clonar')
        setModal(
            <Dialog open={true} onClose={''}>
                <DialogTitle>
                    {'Clonar'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <strong>Clique em Clonar para que mais uma nota com os mesmos parâmetros seja criada.</strong>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={fecharModal} color="secondary">
                        Cancelar
                    </Button>
                    <Button onClick={() => { handleClickClone(item); fecharModal() }} color="primary">
                        Clonar
                    </Button>
                </DialogActions>
            </Dialog>
        )
    };
    return (
        <>
            {modal}
            <List>
                {data.map((item, index) => {

                    return (
                        <CardBox
                            key={item.id}
                            styleName="col-lg-12 col-md-6 col-12"
                            cardStyle="p-0"
                            heading={""}
                            headerOutside
                        >

                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <ShoppingCartIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                {item.lo_pedido === 'S' ? (
                                    <ListItemText style={{ width: "15%" }} className="br-break"
                                        primary={`${item.id}`}
                                        secondary={'Orçamento'} />
                                ) : (
                                    <ListItemText style={{ width: "15%" }} className="br-break"
                                        primary={`${item.nu_nota ? `${item.tx_tipo_documento === 'NFSE' ? item.nu_nota + '(RPS)' : item.nu_nota}` : '...'}/${item.nu_serie ? item.nu_serie : '...'}`}
                                        secondary={item.tx_tipo_documento} />
                                )}

                                <ListItemText style={{ width: "16%" }} className="br-break" primary={item.vr_total ? "R$" + item.vr_total.replace('.', ',') : null} />
                                <ListItemText style={{ width: "20%" }} className="br-break" primary={item.dt_evento ? moment(item.dt_evento.slice(0, 10)).format("DD/MM/YYYY") : null} />
                                <ListItemText style={{ width: "18%" }} className="br-break" primary={item.dt_emissao ? moment(item.dt_emissao.slice(0, 10)).format("DD/MM/YYYY") : null} />
                                <ListItemText style={{ width: "10%" }} className="br-break">
                                    {getBadge(item)}
                                </ListItemText>


                                <ListItemText style={{ width: "16%" }}>
                                    <ListItemSecondaryAction style={{ flexDirection: 'row' }}>
                                        <EyeIcon
                                            color={item.tx_status_venda_nf === '101' || item.tx_status_venda_nf === '100' ? "action" : "disabled"}
                                            style={{
                                                marginLeft: 5, cursor: item.tx_status_venda_nf === '101' || item.tx_status_venda_nf === '100' || item.tx_status_venda_nf === '150' || item.tx_status_venda_nf === 'L000' ? "pointer" :
                                                    "default"
                                            }}
                                            onClick={() => {
                                                if (item.tx_status_venda_nf === '101' || item.tx_status_venda_nf === '100' || item.tx_status_venda_nf === '150' || item.tx_status_venda_nf === 'L000') {
                                                    venda(item.id)
                                                }
                                            }} />
                                        {item.lo_pedido !== 'S' ? (
                                            <EditIcon style={{ cursor: item.tx_status_venda_nf === '101' || item.tx_status_venda_nf === '100' ? "pointer" : "default" }}
                                                color={item.tx_status_venda_nf === '101' || item.tx_status_venda_nf === '100' ? "disabled" : "action"}
                                                onClick={() => item.tx_status_venda_nf === '100' || item.tx_status_venda_nf === '101' ? false : checkOption(item.id, index)}
                                            />
                                        ) : ''}

                                        {item.lo_pedido !== 'S' ? (
                                            <LibraryAddIcon
                                                onClick={() => clone(item.id, index)}
                                            />
                                        ) : ''}

                                        {/* <NoteIcon style={{ cursor: item.tx_status_venda_nf === '101' || item.tx_status_venda_nf === '100' ? "pointer" : "default" }}
                                    color={item.tx_status_venda_nf === '101' || item.tx_status_venda_nf === '100' ? "disabled" : "action"}
                                    onClick={() => item.tx_status_venda_nf === '100' || item.tx_status_venda_nf === '101' ? false : emitir(item.id, index)} /> */}

                                    </ListItemSecondaryAction>
                                </ListItemText>

                            </ListItem>
                        </CardBox>
                    );
                })}
            </List>
        </>);
};

export default ListVendas;
