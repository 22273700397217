import axios from 'axios';
import { userSignOut } from 'actions';
const token = sessionStorage.getItem("@Emissor/Token");
const url = process.env.REACT_APP_API_URL;
//let url = "http://localhost:3006/";
//url = "/api"; //PRODUÇÃO E HOMOL

const deslogar = () => {
    sessionStorage.clear();
    userSignOut()
    window.location.href = '/';
}

const api = axios.create({
    baseURL: url,
    headers: {
        "Authorization": `Bearer ${token}`
    }
});

// qualquer requisição usando a api que tiver retorno 401 é redirecionado para a tela de login
api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            // Token expirado, deslogar o usuário e redirecionar para a tela de login
            deslogar();
        }
        return Promise.reject(error);
    }
);

export default api;

