import React from 'react';
import { MdCheckCircle } from 'react-icons/md';
import { Typography } from '@material-ui/core';


export const SuccessAlert = ({ message, close, isLoggedUser, text }) => {
    return (

        <div style={{
            display: "flex", width: "100%", height: "100%", flexDirection: 'column', aliginItems: 'center', justifyContent: 'center'
            // , backgroundColor: "#00b300"
        }}>
            <MdCheckCircle size={30} color="white" style={{ alignSelf: 'center' }} />
            <p style={{ fontSize: 16, textAlign: 'center', color: "#fff" }}>{message}</p>
            {/* <a style={{ textAlign: "center" }}>{campos}</a> */}
            <div style={{
                width: 120, height: 30, alignItems: 'center', justifyContent: "center",
                borderRadius: 7, backgroundColor: '#fff', alignSelf: "center", cursor: 'pointer'
            }} onClick={() => {
                close(false);
            }}>
                <Typography style={{ color: '#00b300', textAlign: "center", marginTop: 3 }} >{text ? text : "Realizar login"}</Typography>
            </div>
        </div>


    )
}