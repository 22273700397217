import * as yup from 'yup';
const removeFormatting = (value) => value ? value.replace(/[.-/]/g, '') : '';


export const stepOne = yup.object().shape({
    name: yup.string()
        .when('lo_possui_cadastro', {
            is: false,
            then: yup.string().required("Campo obrigatório")
        }),
    //cpf_cnpj: yup.string().min(14, "Informe o CPF ou CNPJ corretamente").required("Campo obrigatório"),
    email: yup.string()
        .when('lo_possui_cadastro', {
            is: false,
            then: yup.string()
                .when('email_error', {
                    is: true,
                    then: yup.string()
                        .min(999, ("Email Já Cadastrado"))
                }).email("Informe o e-mail corretamente").required("Campo obrigatório"),
        }),

    password: yup.string()
        .when('lo_possui_cadastro', {
            is: false,
            then: yup.string().required("Campo obrigatório")
        }),

    telefone: yup.string()
        .when('lo_possui_cadastro', {
            is: false,
            then: yup.string().min(13, "Informe o telefone corretamente").required("Campo obrigatório")
        }),

    password_confirm: yup.string()
        .when('lo_possui_cadastro', {
            is: false,
            then: yup.string().oneOf([yup.ref('password'), null], "As senhas precisam ser idênticas")
                .required('Campo obrigatório'),
        }),

    cpf_cnpj: yup.string()
        .when('lo_possui_cadastro', {
            is: false,
            then: yup.string().test('cpf-cnpj', 'Informe o CPF ou CNPJ corretamente', (value) => {
                if (!value) {
                    return false; // Valor é undefined ou nulo
                }
                if (value.length === 14 || value.length === 18) {
                    return true;
                }
                return false;
            })
                .required("Campo obrigatório"),
        }),


    login_email:
        yup.string()
            .when('lo_possui_cadastro', {
                is: true,
                then: yup.string().email("Informe o e-mail corretamente").required("Campo obrigatório"),
            }),

    login_password: yup.string()
        .when('lo_possui_cadastro', {
            is: true,
            then: yup.string().required('Senha Inválida'),
        })
    // .when('StartIntensity', (StartIntensity, schema) => {
    //     return schema.test({
    //       test: EndIntensity => !!StartIntensity && EndIntensity > StartIntensity,
    //       message: "Max should be > min"
    //     })
    //   })
});
export const stepThree = yup.object().shape({
    razao_social: yup.string().required("Campo obrigatório"),
    cnpj: yup.string().min(18, "Informe o CNPJ corretamente").required("Campo obrigatório"),
    nome_fantasia: yup.string().required("Campo obrigatório"),
    crt: yup.string().required("Campo obrigatório"),
    email_empresa: yup.string().email("Informe o e-mail corretamente").required("Campo obrigatório"),
    ie: yup.string().required("Campo obrigatório"),
    im: yup.string().required("Campo obrigatório"),
    emp_telefone: yup.string().min(13, "Informe o telefone corretamente").required("Campo obrigatório"),
    cnae: yup.string().required("Campo obrigatório"),
})
export const stepFour = yup.object().shape({
    e_cep: yup.string().required("Campo obrigatório"),
    e_bairro: yup.string().required("Campo obrigatório"),
    e_logradouro: yup.string().required("Campo obrigatório"),
    e_cmun: yup.string().required("Campo obrigatório"),
    e_cuf: yup.string().required("Campo obrigatório"),
    e_uf: yup.string().required("Campo obrigatório"),
    e_nro: yup.string().required("Campo obrigatório"),
    e_complemento: yup.string().required("Campo obrigatório"),
    e_cidade: yup.string().required("Campo obrigatório"),
});

// export const stepThreeSchema = ;


// export const stepFourSchema = ;




// yup.object().shape({
// razao_social: yup.string().required("Campo obrigatório"),
// cnpj: yup.string().required("Campo obrigatório"),
// nome_fantasia: yup.string().required("Campo obrigatório"),
// crt: yup.string().required("Campo obrigatório"),
// email_empresa: yup.string().required("Campo obrigatório"),
// ie: yup.string().required("Campo obrigatório"),
// im: yup.string().required("Campo obrigatório"),
// emp_telefone: yup.string().required("Campo obrigatório"),
// cnae: yup.string().required("Campo obrigatório"),
// });