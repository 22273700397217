import api from '.';

export const _getStyles = async (url) => {
    if (url.includes('http://')) {
        url = url.replace('http://', '');
    }
    else if (url.includes("https://")) {
        url = url.replace("https://", '');
    };

    return await api.get(`parceiro/estilo/${url}`).then(response => { return response });
}

export const _getAnounces = async (id) => {
    return await api.get(`parceiro/anuncio/${id}`).then(response => { return response });
}

export const _getEmpresasParceiro = async (cnpj) => {
    return await api.get(`parceiro/empresas/${cnpj ? cnpj : ""}`).then(response => { return response });
}

export const _deleteEmpresa = async (cnpj) => {
    return await api.get(`parceiro/desativar/empresa/${cnpj}`).then(response => { return response });
}

export const _getUsers = async (cnpj) => {
    return await api.get(`parceiro/usuarios/empresas/${cnpj}`).then(response => { return response });
}

export const _createUser = async (data) => {
    return await api.post(`usuarios`, data).then(response => { return response });
}

export const _deleteUser = async (data) => {
    return await api.put("usuarios", { ...data, lo_ativo: 'N' }).then(response => { return response });
}

export const _vinculaUserEmp = async (id_usuario, cnpj) => {
    return await api.post("empresas/vinculaUsuario", { id_usuario, cnpj }).then(response => { return response });
}

export const _filtraEmpresa = async (data) => {
    return await api.post("parceiro/empresas/filtro", data).then(response => { return response });
}