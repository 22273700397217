import React from 'react';
import { MdWarning } from 'react-icons/md';
import { Typography } from '@material-ui/core';


export const WarningAlert = ({ message, close }) => {
    return (

        <div style={{
            display: "flex", width: "100%", height: "100%", flexDirection: 'column', aliginItems: 'center', justifyContent: 'center'
            // , backgroundColor: "#00b300"
        }}>
            <MdWarning size={30} color="white" style={{ alignSelf: 'center' }} />
            <p style={{ fontSize: 16, textAlign: 'center', color: "#fff" }}>{message}</p>
            {/* <a style={{ textAlign: "center" }}>{campos}</a> */}
            <div style={{
                width: 120, height: 30, alignItems: 'center', justifyContent: "center",
                borderRadius: 7, backgroundColor: '#fff', alignSelf: "center", cursor: 'pointer'
            }} onClick={close}>
                <Typography style={{ color: '#ffa64d', textAlign: "center", marginTop: 3 }} >Ok</Typography>
            </div>
        </div>


    )
}