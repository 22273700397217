import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom'
//import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import IntlMessages from 'util/IntlMessages';
import { ToastContainer, toast } from 'react-toastify';
// import {
//     hideMessage,
//     userSignInSuccess
// } from 'actions/Auth';
import { _auth, getUser, _recuperarSenha } from '../services/login.service';
//import FormDialog from 'app/routes/components/routes/dialogs/formAlerts/FormDialog';
//import CheckBoxListControl from 'app/routes/components/routes/list/checkBoxListControl/CheckBoxListControl';
import { makeStyles } from '@material-ui/core/styles';
import { logoImg, primary } from 'configs/CustomColors';
import { Typography } from '@material-ui/core';

const headers = { headers: { "Authorization": "Bearer " + sessionStorage.getItem("@Emissor/Token") } }

const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

// const error = (message) => {
//     toast.error(message, {
//         position: toast.POSITION.TOP_RIGHT,
//         autoClose: 3000
//     });
// }


const RecoverPassword = (props) => {
    const classes = useStyles();
    const [backdrop, setBackdrop] = useState(false);
    const [mailError, setMailError] = useState(false);
    const [tx_email, setMail] = useState('');
    const [changeScreen, setChangeScreen] = useState(false);
    const [changeData, setChangeData] = useState({ message: '', error: false });
    const history = useHistory();

    const sendData = async () => {
        await _recuperarSenha({ tx_email, parceiro: sessionStorage.getItem("@Emissor/parceiro") }, headers)
            .then(response => {
                setChangeData({
                    message: response.data.msg, error: false
                });
                setChangeScreen(true);
            })
            .catch(errp => {
                setMailError(true);
            })
    }

    if (changeScreen) {
        return (
            <>
                <div
                    className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                    <div className="app-login-main-content">

                        <div style={{ display: 'flex', padding: 15, alignItems: 'center', justifyContent: "center", flexDirection: "column" }}>
                            <div className="app-login-header mb-4">
                                <h1>{changeData.message}</h1>
                            </div>

                            <div className="app-login-form">
                                <form>
                                    <fieldset>
                                        <Typography style={{ marginBottom: 20 }}>Verifique sua caixa de entrada ou spam.</Typography>
                                        <div className="mb-12 d-flex align-items-center justify-content-center">
                                            <Button onClick={() => {
                                                history.push('/signin');
                                            }} variant="contained" style={{ backgroundColor: primary, color: "white" }}>
                                                Login
                                            </Button>
                                        </div>

                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <>

                <Backdrop style={{ zIndex: '99999' }} className={classes.backdrop} open={backdrop}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <ToastContainer />
                <div
                    className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                    <div className="app-login-main-content">
                        <div style={{ display: "flex", width: "100%", alignItems: 'center', justifyContent: "center", marginTop: 20 }}>
                            <Link to="/signin">
                                <IntlMessages id="signUp.login" />
                            </Link>
                        </div>

                        <div className="app-logo-content d-flex align-items-center justify-content-center">
                            <Link className="logo-lg" to="/app/vendas/area" title="Logo">
                                <img src={`data:image/png;base64,${logoImg}`} style={{ width: "100%", height: "100%" }} alt="Logo" title="Logo" />
                            </Link>
                        </div>

                        <div className="app-login-content">
                            <div className="app-login-header mb-4">
                                <h1>Recuperar Senha</h1>
                            </div>

                            <div className="app-login-form">
                                <form>
                                    <fieldset>
                                        <TextField
                                            label={<IntlMessages id="appModule.email" />}
                                            fullWidth
                                            error={mailError}
                                            helperText={mailError && "E-mail não encontrado na base de dados."}
                                            onChange={(event) => setMail(event.target.value)}
                                            defaultValue={tx_email}
                                            margin="normal"
                                            className="mt-1 my-sm-3"
                                        />
                                        <div className="mb-3 d-flex align-items-center justify-content-between">
                                            <Button onClick={() => {
                                                sendData()
                                            }} variant="contained" style={{ backgroundColor: primary, color: "white" }}>
                                                <IntlMessages id="appModule.forgot" />
                                            </Button>
                                        </div>

                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};


export default RecoverPassword;
