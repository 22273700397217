export const defaults = returnStorageItem("@Emissor/barColor") || '#392b58';
export const primary = returnStorageItem("@Emissor/primaryColor") || '#4d79ff';
export const secondary = returnStorageItem("@Emissor/secondaryColor") || '#6c969d';
export const success = returnStorageItem("@Emissor/successColor") || '#00cc00';
export const error = returnStorageItem("@Emissor/dangerColor") || '#ff6666';
export const warning = returnStorageItem("@Emissor/warningColor") || '#E28413';
export const logoImg = '../assets/images/adaptive-icon.png';

function returnStorageItem(path) {
    if (sessionStorage.getItem(path) !== 'undefined') {
        return sessionStorage.getItem(path)
    } else {
        return null;
    }
}