import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FilterIcon from '@material-ui/icons/TuneOutlined';

const options = [
  'Editar',
  'Excluir'
];


class LongMenu extends Component {
  state = {
    anchorEl: undefined,
    open: false,
  };

  handleClick = event => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  checkOption(option, item) {
    if (option === 'Editar') {
      this.props.setEdit(this.props.value);
    } else {
      if (this.props.company) {
        this.props.alert(this.props.value.cnpj);
      }
      else if (this.props.userParceiro) {
        this.props.alert(this.props.value);
      } else {
        this.props.alert(this.props.value.id);
      }
    }
  }

  render() {
    if (this.props.locale === 'search') {
      return (
        <div>
          <IconButton
            aria-label="More"
            aria-owns={this.state.open ? 'long-SidenavContent.js' : null}
            aria-haspopup
            onClick={this.handleClick}
          >
            <FilterIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={this.state.anchorEl}
            open={this.state.open}
            onClose={this.handleRequestClose}
            MenuListProps={{
              style: {
                width: 200,
              },
            }}
          >
            {this.props.children}
          </Menu>
        </div>
      );
    } else {
      return (
        <div>
          <IconButton
            aria-label="More"
            aria-owns={this.state.open ? 'long-SidenavContent.js' : null}
            aria-haspopup
            onClick={this.handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={this.state.anchorEl}
            open={this.state.open}
            onClose={this.handleRequestClose}
            MenuListProps={{
              style: {
                width: 200,
              },
            }}
          >
            {options.map(option =>
              <MenuItem key={option} onClick={() => this.checkOption(option)}>
                {option}
              </MenuItem>,
            )}
          </Menu>
        </div>
      );
    }
  }
}

export default LongMenu;