import api from '.';

export const _saveEmpresa = async (json, headers) => {
    return await api.post('empresas', json, headers).then(response => { return response });
}

export const _getStatusPagamento = async (json, headers) => {
    return await api.post('empresas/statusPagamento', json, headers).then(response => { return response });
}

export const _geraPagamento = async (values, headers) => {
    return await api.post('empresas/geraPagamento', values, headers).then(response => { return response });
}