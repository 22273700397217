import React, { useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import moment from 'moment';
import EyeIcon from '@material-ui/icons/Visibility';
import CardBox from '../../../../../../components/CardBox/index';
import { Button } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';

const ListConsulta = ({ data, consulta }) => {
    return (
        <List>
            {data.map((item, index) => {
                return (
                    <CardBox
                        styleName="col-lg-12 col-md-6 col-12"
                        cardStyle="p-0"
                        heading={""}
                        headerOutside
                    >
                        <ListItem key={item.id}>
                            <ListItemAvatar>
                                <Avatar>
                                    <SearchIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText style={{ width: "15%" }} className="br-break"
                                primary={`${item.nu_nota ? item.nu_nota : '...'}/${item.nu_serie ? item.nu_serie : '...'}`}
                                secondary={item.tx_tipo_documento} />
                            <ListItemText style={{ width: "15%" }} className="br-break" primary={item.vr_total ? "R$" + item.vr_total : null} />
                            <ListItemText style={{ width: "15%" }} className="br-break" primary={item.dt_evento ? moment(item.dt_evento.slice(0, 10)).format("DD/MM/YYYY") : null} />
                            <ListItemText style={{ width: "15%" }} className="br-break" primary={item.dt_emissao ? moment(item.dt_emissao.slice(0, 10)).format("DD/MM/YYYY") : null} />
                            <ListItemSecondaryAction style={{ flexDirection: 'row' }}>
                                <Button color="green" onClick={() => consulta(item.id, item.nu_nota, item.nu_serie)} startIcon={<EyeIcon />} >Consultar</Button>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </CardBox>
                );
            })}
        </List>
    );
};

export default ListConsulta;
