import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Typography } from '@material-ui/core';

const FormDialog = (props) => {
  const [open, setOpen] = useState(true);

  const handleRequestClose = () => {
    setOpen(false);
    props.close();
  };

  return (
    <Dialog
      BackdropProps={props.actions ? { style: { backgroundColor: 'black', opacity: .8 } } : null}
      fullWidth
      fullScreen={props.vendas}
      maxWidth={props.maxWidth}
      style={{
        width: "100%",
        height: props.nf ? "90%" : null,
      }}
      open={open}
      onClose={() => props.locale ? props.close() : null}
    >
      <DialogTitle style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: "100%",
        alignItems: "center",
        backgroundColor: props.error ? '#ff6666' : props.success ? "#00b300" : props.warning ? "#ffa64d" : null,
        height: 15
      }}>
        <div style={{ display: 'flex', width: "100%", justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: "flex", width: "50%" }}>
            <Typography style={{ fontWeight: 'bold' }}>{props.title}</Typography>
          </div>
          {props.locale === 'Vendas' &&
            <IconButton
              aria-label="close"
              onClick={handleRequestClose}
            >
              <CloseIcon style={{ color: props.error || props.success ? 'white' : null }} />
            </IconButton>
          }
        </div>
      </DialogTitle>
      <DialogContent style={{
        backgroundColor: props.error ? '#ff6666' : props.success ? "#00b300" : props.warning ? "#ffa64d" : null
      }}>
        <DialogContentText />
        {props.children}
      </DialogContent>
    </Dialog>
  );
};

export default FormDialog;
