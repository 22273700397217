import React from 'react';
import { MdNotificationsActive } from 'react-icons/md';
import { Typography } from '@material-ui/core';
import styles from "./styles.module.scss";


export const ErrorAlert = ({ message, campos, handleClose, openDetails }) => {
    const fechar = function fechar(params) {
        handleClose();
    }
    return (
        <div className={styles["container"]}>
            <MdNotificationsActive
                size={30}
                color="white"
                style={{ alignSelf: 'center' }}
            />
            <p className={styles["message"]}>{message ? message : "Erro ao tentar emitir a nota. Tente novamente."}</p>
            <div
                className={styles["confirmButtonContainer"]}
                onClick={fechar}>
                <Typography className={styles["confirmButton"]}>OK</Typography>
            </div>
            {campos && <div
                className={styles["detailsContainer"]}
                onClick={() => openDetails(campos)}>
                <Typography className={styles["detailsLabel"]}>Detalhes</Typography>
            </div>}
        </div>
    )
}