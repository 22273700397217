export const formaPagamento = [
    { label: 'Dinheiro', id: "01" },
    { label: 'Cartão de Crédito', id: '03' },
    { label: 'Cartão de Débito', id: '04' },
    { label: 'Cheque', id: '02' },
    { label: 'Crédito Loja', id: '05' },
    { label: 'Vale Alimentação', id: '10' },
    { label: 'Vale Refeição', id: '11' },
    { label: 'Vale Presente', id: '12' },
    { label: 'Vale Combustível', id: '13' },
    { label: 'Boleto Bancário', id: '15' },
    { label: 'Sem pagamento', id: '90' },
    { label: 'Outros', id: '99' }
];

export const clientes = [
    { label: "Cliente 1", value: "Cliente 1" },
    { label: "Cliente 2", value: "Cliente 2" },
    { label: "Cliente 3", value: "Cliente 3" },
    { label: "Cliente 4", value: "Cliente 4" },
    { label: "Cliente 5", value: "Cliente 5" },
];

export const vendas = [
    { data: "16/01/2021", valor: "12.00", n_nota: 19855, f_pagamento: "DI" },
    { data: "01/12/2020", valor: "15.00", n_nota: 19854, f_pagamento: "CD" },
    { data: "01/01/2021", valor: "30.00", n_nota: 19853, f_pagamento: "CD" },
    { data: "01/01/2021", valor: "25.00", n_nota: 19852, f_pagamento: "DI" },
    { data: "01/01/2021", valor: "45.00", n_nota: 19851, f_pagamento: "CC" },
    { data: "01/01/2021", valor: "70.00", n_nota: 19850, f_pagamento: "CC" },
    { data: "01/01/2021", valor: "9.85", n_nota: 19849, f_pagamento: "CC" },
    { data: "01/01/2021", valor: "10.00", n_nota: 19848, f_pagamento: "PX" },
    { data: "01/01/2021", valor: "8.00", n_nota: 19847, f_pagamento: "CD" },
    { data: "30/12/2020", valor: "5.00", n_nota: 19846, f_pagamento: "DI" },
];

export const empresa = [
    { label: "Empresa de teste", value: "EmPT" }
];

export const listaClientes = [
    { nome: "Cliente 1", cpf: "000.000.000-00", cep: "00.000-00", uf: "DF" },
    { nome: "Cliente 2", cpf: "000.000.000-00", cep: "00.000-00", uf: "DF" },
    { nome: "Cliente 3", cpf: "000.000.000-00", cep: "00.000-00", uf: "DF" },
    { nome: "Cliente 4", cpf: "000.000.000-00", cep: "00.000-00", uf: "DF" },
    { nome: "Cliente 5", cpf: "000.000.000-00", cep: "00.000-00", uf: "DF" },
    { nome: "Cliente 6", cpf: "000.000.000-00", cep: "00.000-00", uf: "DF" },
];


export const formaPg = [
    { tx_descricao: "Dinheiro", tx_bandeira: null, lo_ativo: "S", nu_cnpj_empresa: "00.898.594/0001-08", tx_razao_social: "Empresa Teste 1" },
    { tx_descricao: "Cartão de Crédido", tx_bandeira: "Visa", lo_ativo: "S", nu_cnpj_empresa: "00.898.594/0001-08", tx_razao_social: "Empresa Teste 2" },
    { tx_descricao: "Pix", tx_bandeira: null, lo_ativo: "S", nu_cnpj_empresa: "00.898.594/0001-08", tx_razao_social: "Empresa Teste 3" },
    { tx_descricao: "Cheque", tx_bandeira: null, lo_ativo: "N", nu_cnpj_empresa: "00.898.594/0001-08", tx_razao_social: "Empresa Teste 4" },
    { tx_descricao: "Cartão de Débito", tx_bandeira: "MasterCard", lo_ativo: "S", nu_cnpj_empresa: "00.898.594/0001-08", tx_razao_social: "Empresa Teste 5" },
];


export const configs = [
    { nu_cnpj_empresa: "00.898.594/0001-08", tx_razao_social: "Empresa Teste 1", sequencial_nfc: "00001", serie_nfc: 1, sequencial_nfe: "00002", serie_nfe: 2, lo_ativo: "S" },
    { nu_cnpj_empresa: "00.800.500/0002-09", tx_razao_social: "Empresa Teste 2", sequencial_nfc: "00003", serie_nfc: 3, sequencial_nfe: "00004", serie_nfe: 4, lo_ativo: "N" }
]

export const tributacao = [
    {
        nu_cnpj_empresa: "00.800.500/0002-09", tx_razao_social: "Empresa Teste 2", tx_descricao: "Tributo", vr_perc_icms: '2%', vr_perc_iss: '15%', vr_perc_pis: "12%", vr_perc_cofins: "8%",
        lo_isento_iss: "N", lo_ativo: "S"
    },
    {
        nu_cnpj_empresa: "00.800.500/0002-09", tx_razao_social: "Empresa Teste 2", tx_descricao: "Tributo", vr_perc_icms: '2%', vr_perc_iss: '15%', vr_perc_pis: "12%", vr_perc_cofins: "8%",
        lo_isento_iss: "N", lo_ativo: "S"
    },
    {
        nu_cnpj_empresa: "00.800.500/0002-09", tx_razao_social: "Empresa Teste 2", tx_descricao: "Tributo", vr_perc_icms: '2%', vr_perc_iss: '15%', vr_perc_pis: "12%", vr_perc_cofins: "8%",
        lo_isento_iss: "N", lo_ativo: "S"
    },
    {
        nu_cnpj_empresa: "00.800.500/0002-09", tx_razao_social: "Empresa Teste 2", tx_descricao: "Tributo", vr_perc_icms: '2%', vr_perc_iss: '15%', vr_perc_pis: "12%", vr_perc_cofins: "8%",
        lo_isento_iss: "N", lo_ativo: "S"
    },
]

export const bandeiras = [
    { id: "T", label: "Todas" },
    { id: '01', label: "Visa" },
    { id: "02", label: "Mastercard" },
    { id: '03', label: "American Express" },
    { id: '04', label: "Sorocred" },
    { id: '05', label: "Diners Club" },
    { id: '06', label: "Elo" },
    { id: '07', label: "Hipercard" },
    { id: '08', label: "Aura" },
    { id: '09', label: "Cabal" },
    { id: '10', label: "Alelo" },
    { id: '11', label: "Banes Card" },
    { id: '12', label: "CalCard" },
    { id: '13', label: "Credz" },
    { id: '14', label: "Discove" },
    { id: '15', label: "GoodCard" },
    { id: '16', label: "GreenCard" },
    { id: '17', label: "Hiper" },
    { id: '18', label: "JcB" },
    { id: '19', label: "Mais" },
    { id: '20', label: "MaxVan" },
    { id: '21', label: "Policard" },
    { id: '22', label: "RedeCompras" },
    { id: '23', label: "Sodexo" },
    { id: '24', label: "ValeCard" },
    { id: '25', label: "Verocheque" },
    { id: '26', label: "VR" },
    { id: '27', label: "Ticket" },
    { id: '99', label: "Outros" }
]

export const loAtivo = [
    { id: "T", label: "Todos" },
    { id: "S", label: "Apenas ativos" },
    { id: "N", label: "Apenas inativos" },
];

export const loServico = [
    { id: "T", label: "Todos" },
    { id: "S", label: "Apenas serviços" },
    { id: "N", label: "Apenas produtos" },
];

export const loAtivoForm = [
    { id: "S", label: "Sim" },
    { id: "N", label: "Não" },
];

export const tipoPrazo = [
    { id: 0, label: 'À vista' },
    { id: 1, label: 'À prazo' }
];

export const permiteTroco = [
    { id: "S", label: "Sim" },
    { id: "N", label: "Não" },
]


export const tipoPessoa = [
    { id: "F", label: "Física" },
    { id: "J", label: "Jurídica" },
]

export const ieDest = [
    { id: 1, label: "Contribuinte ICMS" },
    { id: 2, label: "Contribuinte isento" },
    { id: 9, label: "Não contribuinte" }
]

export const prodServ = [
    { id: "P", label: "Produto" },
    { id: "S", label: "Serviço" },
]

export const cstcsosn = [
    { id: "00", label: "00 - Tributada integralmente" },
    { id: "10", label: "10 - Tributada e com cobrança do ICMS por substituição tributária" },
    { id: "20", label: "20 - Com redução da BC" },
    { id: "30", label: "30 - Isenta / não tributada e com cobrança do ICMS por substituição tributária" },
    { id: "40", label: "40 - Isenta" },
    { id: "41", label: "41 - Não tributada" },
    { id: "50", label: "50 - Com suspensão" },
    { id: "51", label: "51 - Com diferimento" },
    { id: "60", label: "60 - ICMS cobrado anteriormente por substituição tributária" },
    { id: "70", label: "70 - Com redução da BC e cobrança do ICMS por substituição tributária" },
    { id: "90", label: "90 - Outras (CST)" },
];


export const cstcsosn2 = [
    { id: "101", label: "101 - Tributada pelo Simples Nacional com permissão de crédito" }, //FIXME:
    { id: "102", label: "102 - Tributada pelo Simples Nacional sem permissão de crédito" },
    { id: "103", label: "103 - Isenção do ICMS no Simples Nacional para faixa de receita bruta" },
    { id: "201", label: "201 - Tributada pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por substituição tributária" },
    { id: "202", label: "202 - Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por substituição tributária" },
    { id: "203", label: "203 - Isenção do ICMS no Simples Nacional para faixa de receita bruta e com cobrança do ICMS por substituição tributária " },
    { id: "300", label: "300 - Imune" },
    { id: "400", label: "400 - Não tributada pelo Simples Nacional" },
    { id: "500", label: "500 - ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação" },
    { id: "900", label: "900 - Outras (CSOSN)" },
]

export const regime_tributario = [
    { label: "Simples Nacional", id: "1" },
    { label: "Simples Nacional - Excesso de sublimite de receita bruta", id: "2" },
    { label: "Regime Normal", id: "3" },
]


export const exigibilidade = [
    { id: "1", label: "Exigível" },
    { id: "2", label: "Não incidência" },
    { id: "3", label: "Isenção" },
    { id: "4", label: "Exportação" },
    { id: "5", label: "Imunidade" },
    { id: "6", label: "Exigibilidade Suspensa por Decisão Judicial" },
    { id: "7", label: "Exigibilidade Suspensa por Processo Administrativo" }
];

export const baseCalculoICMS = [
    { id: "0", label: "Margem Valor Agregado (%)" },
    { id: "1", label: "Pauta (Valor)" },
    { id: "2", label: "Preço Tabelado Máx. (valor)" },
    { id: "3", label: "Valor da operação" }
];

export const modBcSbtTribICMS = [
    { id: "0", label: "Preço tabelado ou máximo sugerido" },
    { id: "1", label: "Lista Negativa (valor)" },
    { id: "2", label: "Lista Positiva (valor)" },
    { id: "3", label: "Lista Neutra (valor)" },
    { id: "4", label: "Margem Valor Agregado (%)" },
    { id: "5", label: "Pauta (valor)" }
];

export const tiposDeIsencaoICMS = [
    { id: "1", label: "Táxi" },
    { id: "2", label: "Deficiente Físico (Revogado)" },
    { id: "3", label: "Produtor Agropecuário" },
    { id: "4", label: "Frotista/Locadora" },
    { id: "5", label: "Diplomático/Consular" },
    { id: "6", label: "Utilitários e Motocicletas da Amazônia Ocidental e Áreas de Livre Comércio" },
    { id: "7", label: "SUFRAMA" },
    { id: "8", label: "Venda a Órgãos Públicos" },
    { id: "9", label: "Outros" },
    { id: "10", label: "Deficiente Condutor (Convênio ICMS 38/12)" },
    { id: "11", label: "Deficiente Não Condutor (Convênio ICMS 38/12)" },
    { id: "12", label: "Órgão de Fomento e Desenvolvimento Agropecuário" },
    { id: "16", label: "Olimpíadas Rio 2016" },
    { id: "90", label: "Solicitado pelo Fisco" }
];

export const tipoUser = [
    { id: "AG", label: "Administrador Geral" },
    { id: "NU", label: "Usuário Comum" }
]


export const indpressNFC = [
    { id: "1", label: "Operação presencial" },
    { id: "4", label: "NFC-e em operação com entrega em domicílio" }
]

export const indpress = [
    { id: "1", label: "Operação presencial" },
    { id: "2", label: "Operação não presencial, pela Internet" },
    { id: "3", label: "Operação não presencial,Teleatendimento" },
    { id: "4", label: "NFC-e em operação com entrega em domicílio" },
    { id: "9", label: "Operação não presencial, outros" }
]

export const finnfe = [
    { id: "1", label: "NF-e normal" },
    { id: "2", label: "NF-e complementar" },
    { id: "3", label: "NF-e de ajuste" },
    { id: "4", label: "Devolução de mercadoria" }
]

export const iddest = [
    { id: "1", label: "Operação interna" },
    { id: "2", label: "Operação interestadual" },
    { id: "3", label: "Operação com exterior" },
]

export const tpnf = [
    { id: "0", label: "Entrada" },
    { id: "1", label: "Saída" }
]

export const cstpis = [
    { id: "01", label: "01 - Operação Tributável com Alíquota Básica" },
    { id: "02", label: "02 - Operação Tributável com Alíquota Diferenciada" },
    { id: "03", label: "03 - Operação Tributável com Alíquota por Unidade de Medida de Produto" },
    { id: "04", label: "04 - Operação Tributável Monofásica - Revenda a Alíquota Zero" },
    { id: "05", label: "05 - Operação Tributável por Substituição Tributária" },
    { id: "06", label: "06 - Operação Tributável a Alíquota Zero" },
    { id: "07", label: "07 - Operação Isenta da Contribuição" },
    { id: "08", label: "08 - Operação sem Incidência da Contribuição" },
    { id: "09", label: "09 - Operação com Suspensão da Contribuição" },
    { id: "49", label: "49 - Outras Operações de Saída" },
    { id: "50", label: "50 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Tributada no Mercado Interno" },
    { id: "51", label: "51 - Operação com Direito a Crédito – Vinculada Exclusivamente a Receita Não Tributada no Mercado Interno" },
    { id: "52", label: "52 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita de Exportação" },
    { id: "53", label: "53 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno" },
    { id: "54", label: "54 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas no Mercado Interno e de Exportação" },
    { id: "55", label: "55 - Operação com Direito a Crédito - Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação" },
    { id: "56", label: "56 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno, e de Exportação" },
    { id: "60", label: "60 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Tributada no Mercado Interno" },
    { id: "61", label: "61 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno" },
    { id: "62", label: "62 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita de Exportação" },
    { id: "63", label: "63 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno" },
    { id: "64", label: "64 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas no Mercado Interno e de Exportação" },
    { id: "65", label: "65 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação" },
    { id: "66", label: "66 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno, e de Exportação" },
    { id: "67", label: "67 - Crédito Presumido - Outras Operações" },
    { id: "70", label: "70 - Operação de Aquisição sem Direito a Crédito" },
    { id: "71", label: "71 - Operação de Aquisição com Isenção" },
    { id: "72", label: "72 - Operação de Aquisição com Suspensão" },
    { id: "73", label: "73 - Operação de Aquisição a Alíquota Zero" },
    { id: "74", label: "74 - Operação de Aquisição sem Incidência da Contribuição" },
    { id: "75", label: "75 - Operação de Aquisição por Substituição Tributária" },
    { id: "98", label: "98 - Outras Operações de Entrada" },
    { id: "99", label: "99 - Outras Operações" }

]

export const cstipi = [
    { id: "00", label: "00 - Entrada com Recuperação de Crédito" },
    { id: "01", label: "01 - Entrada Tributada com Alíquota Zero" },
    { id: "02", label: "02 - Entrada Isenta" },
    { id: "03", label: "03 - Entrada Não-Tributada" },
    { id: "04", label: "04 - Entrada Imune" },
    { id: "05", label: "05 - Entrada com Suspensão" },
    { id: "49", label: "49 - Outras Entradas" },
    { id: "50", label: "50 - Saída Tributada" },
    { id: "51", label: "51 - Saída Tributável com Alíquota Zero" },
    { id: "52", label: "52 - Saída Isenta" },
    { id: "53", label: "53 - Saída Não-Tributada" },
    { id: "54", label: "54 - Saída Imune" },
    { id: "55", label: "55 - Saída com Suspensão" },
    { id: "99", label: "99 - Outras Saídas" }
];


export const estados = [
    { sigla: 'AC', nome: 'Acre' },
    { sigla: 'AL', nome: 'Alagoas' },
    { sigla: 'AP', nome: 'Amapá' },
    { sigla: 'AM', nome: 'Amazonas' },
    { sigla: 'BA', nome: 'Bahia' },
    { sigla: 'CE', nome: 'Ceará' },
    { sigla: 'DF', nome: 'Distrito Federal' },
    { sigla: 'ES', nome: 'Espírito Santo' },
    { sigla: 'GO', nome: 'Goiás' },
    { sigla: 'MA', nome: 'Maranhão' },
    { sigla: 'MT', nome: 'Mato Grosso' },
    { sigla: 'MS', nome: 'Mato Grosso do Sul' },
    { sigla: 'MG', nome: 'Minas Gerais' },
    { sigla: 'PA', nome: 'Pará' },
    { sigla: 'PB', nome: 'Paraíba' },
    { sigla: 'PR', nome: 'Paraná' },
    { sigla: 'PE', nome: 'Pernambuco' },
    { sigla: 'PI', nome: 'Piauí' },
    { sigla: 'RJ', nome: 'Rio de Janeiro' },
    { sigla: 'RN', nome: 'Rio Grande do Norte' },
    { sigla: 'RS', nome: 'Rio Grande do Sul' },
    { sigla: 'RO', nome: 'Rondônia' },
    { sigla: 'RR', nome: 'Roraima' },
    { sigla: 'SC', nome: 'Santa Catarina' },
    { sigla: 'SP', nome: 'São Paulo' },
    { sigla: 'SE', nome: 'Sergipe' },
    { sigla: 'TO', nome: 'Tocantins' }
]