import React, { useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import { Badge } from "reactstrap";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import LongMenu from "../../menuNPaper/long/LongMenu";
import { formataCPFCNPJ } from "util/Auxiliary";
import CardBox from '../../../../../../components/CardBox/index';
import { error, success } from "configs/CustomColors";

const ListParceiroUsers = ({ data, setEdit, dispatch, alert }) => {
    return (
        <List>
            {data.map((item, index) => {
                return (
                    <CardBox
                        styleName="col-lg-12 col-md-6 col-12"
                        cardStyle="p-0"
                        heading={""}
                        headerOutside
                    >
                        <ListItem button key={item.id} onClick={() => dispatch(item)}>
                            <ListItemAvatar>
                                <Avatar
                                    src={"data:image/png;base64," + item.imagem}
                                    alt={item.name}
                                />
                            </ListItemAvatar>
                            <ListItemText className="br-break" primary={item.name} secondary={formataCPFCNPJ(item.cpf_cnpj)} />
                            {/* <ListItemText className="br-break" primary={"R$" + item.vr_valor} /> */}
                            <Badge className="mr-4 mt-2 text-uppercase" style={{
                                backgroundColor: item.lo_ativo === 'S' ? success : error,
                                color: "white"
                            }} pill>{item.lo_ativo === 'S' ? "Ativo" : "Inativo"}</Badge>
                            <ListItemSecondaryAction>
                                <LongMenu userParceiro={true} locale="list" setEdit={setEdit} value={item} alert={alert} />
                            </ListItemSecondaryAction>
                        </ListItem>
                    </CardBox>
                );
            })}
        </List>
    );
};

export default ListParceiroUsers;
