import React, { useEffect, useRef, useState } from 'react';
import Avatar from '@material-ui/core/Avatar'
import { connect, useDispatch } from 'react-redux'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IntlMessages from 'util/IntlMessages';
import FormDialog from 'app/routes/components/routes/dialogs/formAlerts/FormDialog';
import { _getEmp, _getUser, _updateMe } from 'services/login.service';
import Button from '@material-ui/core/Button';
import { _deleteNatureza, _getNatureza, _saveNatureza, _updateNatureza } from 'services/natureza.service';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import usuarioSchema from 'schemas/usuario.schema';
import { Field, Formik, Form } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { Grid } from '@material-ui/core';
import { estados } from 'util/index.data';
import { formataCep, formataCPFCNPJ, onlyNumbers } from 'util/Auxiliary';
import CheckBoxListControl from 'app/routes/components/routes/list/checkBoxListControl/CheckBoxListControl';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

//import DropZone from '../../app/routes/extensions/routes/dropzone/Dialog/index'
import { convertBase64 } from 'util/uploadfiles';
import ModalEmpresa from './_modal_editEmpresa'

import {
  userSignInSuccess
} from 'actions/Auth';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const success = (message) => {
  toast.success(message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 3000
  });
}

const error = (message) => {
  toast.error(message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 3000
  });
}

const headers = { headers: { "Authorization": "Bearer " + sessionStorage.getItem("@Emissor/Token") } };

//console.log(sessionStorage.getItem("@Emissor/Token"));

export const FormData = ({ setForm, setBackdrop, parceiro }) => {
  const [edit, setEdit] = useState([]);
  const image = sessionStorage.getItem("@Emissor/UserImg");
  let headers = { headers: { "Authorization": "Bearer " + sessionStorage.getItem("@Emissor/Token") } };

  const getMyUser = async () => {
    setBackdrop(true);
    if (parceiro) {
      setBackdrop(false);
      setEdit(parceiro);
    } else {
      await _getUser(headers).then(response => {
        setBackdrop(false); setEdit(response.data); //console.log(response.data.uf);
      })
        .catch(erro => {
          setBackdrop(false); setForm(false); error("Erro ao buscar dados do usuário")
        });
    }
  }

  useEffect(() => { getMyUser(); }, []);

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click(); // Abre o seletor de arquivo quando o botão é clicado

  };

  const handleFileChange = async (event, setFieldValue) => {
    const selectedFile = event.target.files[0];
    // Faça algo com o arquivo selecionado, como enviá-lo para o servidor ou processá-lo localmente
    setFieldValue('imagem', await convertBase64(selectedFile))

  };


  const updateUser = async (values) => {
    setBackdrop(true);
    //values.id_empresa = sessionStorage.getItem("@Emissor/idEmpresa")
    values.id = edit.id;
    values.tipo = edit.tipo;
    values.cpf_cnpj = onlyNumbers(values.cpf_cnpj)

    await _updateMe(values, headers)
      .then(() => {
        sessionStorage.setItem("@Emissor/UserImg", values.imagem);
        setBackdrop(false);
        setForm(false);
        success('Seu usuário foi atualizado com sucesso!');
      })
      .catch((erro) => {
        setBackdrop(false); //console.log(erro.response.data); error("Erro ao atualizar seu usuário. Tente novamente!");
      });
  }

  delete usuarioSchema.fields.password;
  console.log(usuarioSchema)
  return (
    <Formik enableReinitialize={true}
      initialValues={{
        id_empresa: "",
        name: edit.name, lo_ativo: edit.lo_ativo, cpf_cnpj: edit.cpf_cnpj !== undefined ? formataCPFCNPJ(edit.cpf_cnpj) : edit.cpf_cnpj
        , email: edit.email,
        telefone: edit.telefone, cep: formataCep(edit.cep), nro: edit.nro, endereco: edit.endereco,
        complemento: edit.complemento, cidade: edit.cidade
        , bairro: edit.bairro, uf: edit.uf, imagem: image, ext: ''
      }}
      validationSchema={usuarioSchema}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(await updateUser(values));
      }}
    >
      {({
        values,
        errors,
        setFieldValue
      }) => {
        //console.log(edit.cpf_cnpj);
        return (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', width: "100%" }}>
            <Form>
              <Grid container spacing={3} >
                {
                  parceiro &&

                  <Grid item xs={12} sm={4} >
                    <InputLabel shrink>Empresa</InputLabel>
                    <Field name="id_empresa" variant="outlined" component={Select} fullWidth
                      error={errors.id_empresa} value={parceiro ? parceiro.id_empresa : values.id_empresa}
                      disabled={parceiro.id_empresa}
                      helperText={errors.id_empresa ? "Verifique os dados fornecidos." : null}
                      onChange={event => setFieldValue('id_empresa', event.target.value)}>
                      {parceiro.empresas.map((parca, index) => <MenuItem key={index} value={parca.value}>{parca.label}</MenuItem>)}
                    </Field>
                  </Grid>
                }

                <Grid xs={4} sm={4} container style={{ marginTop: '10px' }} justifyContent="center" >
                  <input
                    type="file"
                    // accept="image/*"
                    accept=".jpg, .jpeg, .png, .gif"
                    style={{
                      position: 'absolute',
                      left: '-9999px',
                      alignSelf: 'center',
                      alignContent: 'center',
                      alignItems: 'center',
                    }}
                    className='dropzone-file-btn'
                    ref={fileInputRef}
                    onChange={(e) => {
                      handleFileChange(e, setFieldValue);
                    }}
                  />
                  <div
                    style={{
                      width: '250px',
                      height: '145px',
                      position: 'relative',
                      zIndex: 1,
                      backgroundImage: `url(${values.imagem ? values.imagem : ''})`,
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center'
                    }}
                  >
                    <Button
                      variant="contained"
                      startIcon={<CloudUploadIcon />}
                      onClick={(e) => {
                        values.imagem ? setFieldValue('imagem', '') : handleButtonClick(e);
                      }}
                      style={{
                        border: '3px dashed #C0C0C0',
                        width: '250px',
                        height: '145px',
                        cursor: 'pointer',
                        position: 'relative',
                        zIndex: 1,
                        backgroundSize: 'contain',
                        backgroundColor: 'rgba(230, 230, 230, 0.5)', // Cor cinza com 50% de opacidade
                        fontWeight: 'bold'
                      }}
                    >
                      <span style={{ backgroundColor: 'white' }}>  {values.imagem ? 'Remover imagem' : 'Upload imagem'} </span>
                    </Button>
                  </div>

                </Grid>
                <Grid item xs={12} sm={4} >

                  <InputLabel shrink>Nome Completo</InputLabel>
                  <Field name="name" variant="outlined" component={TextField} fullWidth
                    error={errors.name}
                    helperText={errors.name ? "Verifique os dados fornecidos." : null}
                    onChange={event => setFieldValue('name', event.target.value)}
                    value={values.name} />
                  <InputLabel shrink style={{ marginTop: '20px' }}>CPF/CNPJ</InputLabel>
                  <Field name="cpf_cnpj" variant="outlined" component={TextField} fullWidth
                    error={errors.cpf_cnpj}
                    helperText={errors.cpf_cnpj ? "Verifique os dados fornecidos." : null}
                    onChange={event => setFieldValue('cpf_cnpj', event.target.value)}
                    value={values.cpf_cnpj} />

                </Grid>

                <Grid item xs={12} sm={4} >
                  <InputLabel shrink>E-mail</InputLabel>
                  <Field name="email" variant="outlined" component={TextField} fullWidth
                    error={errors.email}
                    helperText={errors.email ? "Verifique os dados fornecidos." : null}
                    onChange={event => setFieldValue('email', event.target.value)}
                    value={values.email} />

                  <InputLabel shrink style={{ marginTop: '20px' }}>Senha</InputLabel>
                  <Field name="password" variant="outlined" component={TextField} fullWidth
                    error={errors.password}
                    type="password"
                    helperText={errors.password ? "Verifique os dados fornecidos." : null}
                    onChange={event => setFieldValue('password', event.target.value)}
                    value={values.password} />
                </Grid>

                <Grid item xs={12} sm={4} >
                  <InputLabel shrink>Telefone</InputLabel>
                  <Field name="telefone" variant="outlined" component={TextField} fullWidth
                    error={errors.telefone}
                    helperText={errors.telefone ? "Verifique os dados fornecidos." : null}
                    onChange={event => setFieldValue('telefone', event.target.value)}
                    value={values.telefone} />
                </Grid>
                <Grid item xs={12} sm={4} >
                  <InputLabel shrink>CEP</InputLabel>
                  <Field name="cep" variant="outlined" component={TextField} fullWidth
                    error={errors.cep}
                    helperText={errors.cep ? "Verifique os dados fornecidos." : null}
                    onChange={event => setFieldValue('cep', event.target.value)}
                    value={values.cep} />
                </Grid>
                <Grid item xs={12} sm={4} >
                  <InputLabel shrink>Logradouro</InputLabel>
                  <Field name="endereco" variant="outlined" component={TextField} fullWidth
                    error={errors.endereco}
                    helperText={errors.endereco ? "Verifique os dados fornecidos." : null}
                    onChange={event => setFieldValue('endereco', event.target.value)}
                    value={values.endereco} />
                </Grid>
                <Grid item xs={12} sm={4} >
                  <InputLabel shrink>Nº</InputLabel>
                  <Field name="nro" variant="outlined" component={TextField} fullWidth
                    error={errors.nro}
                    helperText={errors.nro ? "Verifique os dados fornecidos." : null}
                    onChange={event => setFieldValue('nro', event.target.value)}
                    value={values.nro} />
                </Grid>
                <Grid item xs={12} sm={4} >
                  <InputLabel shrink>Complemento</InputLabel>
                  <Field name="complemento" variant="outlined" component={TextField} fullWidth
                    error={errors.complemento}
                    helperText={errors.complemento ? "Verifique os dados fornecidos." : null}
                    onChange={event => setFieldValue('complemento', event.target.value)}
                    value={values.complemento} />
                </Grid>
                <Grid item xs={12} sm={4} >
                  <InputLabel shrink>Bairro</InputLabel>
                  <Field name="bairro" variant="outlined" component={TextField} fullWidth
                    error={errors.bairro}
                    helperText={errors.bairro ? "Verifique os dados fornecidos." : null}
                    onChange={event => setFieldValue('bairro', event.target.value)}
                    value={values.bairro} />
                </Grid>
                <Grid item xs={12} sm={4} >
                  <InputLabel shrink>Cidade</InputLabel>
                  <Field name="cidade" variant="outlined" component={TextField} fullWidth
                    error={errors.cidade}
                    helperText={errors.cidade ? "Verifique os dados fornecidos." : null}
                    onChange={event => setFieldValue('cidade', event.target.value)}
                    value={values.cidade} />
                </Grid>
                <Grid item xs={4} sm={4} >
                  <InputLabel shrink>UF</InputLabel>
                  <Field name="uf" variant="outlined" component={Select} fullWidth
                    error={errors.uf} value={values.uf ?? "DF"}
                    helperText={errors.uf ? "Verifique os dados fornecidos." : null}
                    onChange={event => setFieldValue('uf', event.target.value)}>
                    {estados.map((estado, index) => <MenuItem key={index} value={estado.sigla}>{estado.nome}</MenuItem>)}
                  </Field>
                </Grid>
                <Grid item xs={4} sm={4} ></Grid>



                <Grid item xs={4} sm={4} ></Grid>
                <div style={{ display: 'flex', width: "100%", justifyContent: 'flex-end', flexDirection: "row", marginTop: 10, marginBottom: 20 }}>
                  <Button onClick={() => setForm(false)} variant="text" style={{ marginRight: 5 }}>
                    <span>
                      Cancelar
                    </span>
                  </Button>
                  <Button
                    type="submit"
                    variant="contained" size="large" style={{ margin: '15px', backgroundColor: "#99d5c9", color: "#fff" }}>
                    <span>Atualizar
                    </span>
                  </Button>
                </div>
              </Grid>
            </Form>
          </Box>
        )
      }}
    </Formik >
  )
}

const SelectEmpresa = ({ empresas, thispatch, close }) => {
  console.log('dispacho', thispatch);
  return (
    <>
      <CheckBoxListControl data={empresas} locale={"empresas"} dispatch={thispatch} info={true} />
      <div style={{ display: "flex", width: "100%", justifyContent: "flex-end", paddingRight: 10 }}>
        <Button variant="contained" color="secondary" onClick={() => close()}>Desistir</Button>
      </div>
    </>
  )
}


const UserInfo = ({ auth }) => {
  let headers = { headers: { "Authorization": "Bearer " + sessionStorage.getItem("@Emissor/Token") } };

  const classes = useStyles();
  const dispatch = useDispatch();
  const [empresa, setEmpresa] = useState([]);
  const [anchorE1, setAnchorE1] = useState(null);
  const userName = sessionStorage.getItem("@Emissor/UserName");
  const mail = sessionStorage.getItem("@Emissor/UserMail");
  const image = sessionStorage.getItem("@Emissor/UserImg");
  const token = sessionStorage.getItem("@Emissor/Token");
  const [render, setRender] = useState('Empresa');
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState(false);
  const [formEditEmp, setFormEditEmp] = useState(false);
  const [backdrop, setBackdrop] = useState(false);

  const handleClick = event => {
    setOpen(true);
    setAnchorE1(event.currentTarget);
  };

  const handleRequestClose = () => {
    setOpen(false);
  };

  const openForm = () => {
    setOpen(false);
    setForm(true);
    setRender("Form");
  }

  const thispatch = async (empresa) => {
    console.log('em presa', empresa);
    setForm(false);
    setOpen(false);
    setBackdrop(true);
    if (empresa.dadosLocais) {
      console.log('entrou no if')
      sessionStorage.setItem("@Emissor/idEmpresa", empresa.dadosLocais.id);
      sessionStorage.setItem("@Emissor/cnpj", empresa.dadosLocais.nu_cnpj);
      sessionStorage.setItem("@Emissor/nomeEmpresa", empresa.dadosLocais.tx_razao_social);

      sessionStorage.setItem("@Emissor/ambiente", empresa.ambiente); //FIXME:

      dispatch(userSignInSuccess({ email: mail, password: null, token: token, name: userName, mail }));
      setBackdrop(false);
      window.location.reload(false);
    } else {
      setBackdrop(false);
      error("Empresa não está cadastrada na API. Entre em contato com o suporte");
    }
  }


  const getEmp = async () => {
    setRender("Empresa");
    setOpen(false);
    setBackdrop(true);

    //let cnpj = sessionStorage.getItem("@Emissor/cnpj") ? sessionStorage.getItem("@Emissor/cnpj") : '';

    await _getEmp('', headers).then(response => {
      setBackdrop(false); setEmpresa(response.data); setForm(true); setOpen(false);
    })
      .catch((erro) => {
        //console.log(erro.response);
        setBackdrop(false); error("Ocorreu um erro ao listar empresas")
      });
  }

  const editEmp = async () => {
    setFormEditEmp(true)
    setRender("Edit");
    setOpen(false);
    // setBackdrop(true);
    // await _getEmp(headers).then(response => {
    //   setBackdrop(false); setEmpresa(response.data); setForm(true); setOpen(false);
    // })
    //   .catch((erro) => {
    //     //console.log(erro.response);
    //     setBackdrop(false); error("Ocorreu um erro ao listar empresas")
    //   });
  }

  const closeForm = () => {
    setForm(false);
    setFormEditEmp(false)
  }

  return (
    <>
      <Backdrop style={{ zIndex: '99999' }} className={classes.backdrop} open={backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />
      <div className="user-profile d-flex flex-row align-items-center">
        <Avatar
          src={image}
          className="user-avatar "
        />
        <div className="user-detail">
          <h4 className="user-name d-flex" onClick={handleClick}><span className='text-truncate'>{userName.length < 21 ? userName : userName.substring(0, 18) + '...'}</span> <i
            className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
          </h4>
        </div>
        <Menu className="user-info"
          id="simple-menu"
          anchorEl={anchorE1}
          open={open}
          onClose={handleRequestClose}
          PaperProps={{
            style: {
              minWidth: 120,
              paddingTop: 0,
              paddingBottom: 0
            }
          }}
        >
          <MenuItem onClick={openForm}>
            <i className="zmdi zmdi-account zmdi-hc-fw mr-2" />
            <IntlMessages id="popup.profile" />
          </MenuItem>
          {/* <MenuItem onClick={openForm}>
            <i className="zmdi zmdi-flower-alt zmdi-hc-fw mr-2" />
            <IntlMessages id="popup.styles" />
          </MenuItem> */}
          <MenuItem onClick={() => getEmp()}>
            <i className="zmdi zmdi-swap zmdi-hc-fw mr-2" />
            <IntlMessages id="popup.setting" />
          </MenuItem>

          <MenuItem onClick={() => editEmp()}>
            <i className="zmdi zmdi-settings zmdi-hc-fw mr-2" />
            <IntlMessages id="popup.updateData" />
          </MenuItem>
          {/* <MenuItem onClick={async () => {
            await sessionStorage.clear();
            handleRequestClose();
            dispatch(userSignOut());
          }}>
            <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />

            <IntlMessages id="popup.logout" />
          </MenuItem> */}
        </Menu>
        {

          formEditEmp ? (
            <FormDialog maxWidth={"lg"} title={"Config Empresa"}
              close={closeForm} children={
                <ModalEmpresa setBackdrop={setBackdrop} setForm={setForm} close={closeForm} />}
            ></FormDialog>)

            :

            form && <FormDialog maxWidth={"lg"} title={render === 'Empresa' ? "Selecione uma empresa para trabalhar" : "Meus Dados"}
              close={closeForm} children={render === 'Empresa' ?
                <SelectEmpresa close={() => setForm(false)} empresas={empresa} thispatch={thispatch} /> :
                <FormData setBackdrop={setBackdrop} setForm={setForm} />}
            />}

      </div>
    </>
  );
};

export default connect(state => ({ auth: state.auth }))(UserInfo);


