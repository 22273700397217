import api from '.';

const headers = { headers: { "Authorization": "Bearer " + sessionStorage.getItem("@Emissor/Token") } };

export const _auth = async (json, headers) => {
    return await api.post('auth', json, headers).then(response => { return response });
}

export const _getUser = async (headers) => {
    return await api.get('auth', headers).then(response => { return response });
}

export const _updateMe = async (json, headers) => {
    return await api.put('/usuarios', json, headers).then(response => { return response });
}

export const _getEmp = async (cnpj, h) => {
    //console.log('cnpj?', cnpj);
    // let head = { headers: h ? h : headers, params: body ? body : {}, }
    return await api.get(cnpj ? `/empresas/${cnpj}` : '/empresas', h ? h : headers).then(response => { return response });
}

export const _recuperarSenha = async (headers) => {
    //console.log(headers);
    return await api.post('recuperarsenha', headers).then(response => { return response });
}